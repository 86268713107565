import {
  get,
  post
}
  from '../common/axiosHttp.js'

// 修改警戒水位
export const  updateSiteLevel = params => post('/site/updateSiteLevel', params);

// 修改警戒电量
export const  updateSitePower = params => post('/site/updateSitePower', params);




