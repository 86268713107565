<template>
  <div ref="control">
    <div class="btn-control" v-if="show" @mousedown="controlDown" @mouseup="controlUp">
      <div class="btn btn-close" data-btn="close" @click="show=false"></div>
      <div class="btn btn-yuanjiao" data-btn="yuanjiao" title="远焦"></div>
      <div>
        <div class="btn btn-jinjiao" data-btn="jinjiao" title="近焦"></div>
        <div class="btn btn-shang" data-btn="shang" title="上移"></div>
      </div>
      <div>
        <div class="btn btn-jian" data-btn="jian" title="缩小"></div>
        <div class="btn btn-zuo" data-btn="zuo" title="左移"></div>
        <div class="btn btn-zanting" data-btn="zanting" title="停止移动"></div>
        <div class="btn btn-you" data-btn="you" title="右移"></div>
      </div>
      <div>
        <div class="btn btn-jia" data-btn="jia" title="放大"></div>
        <div class="btn btn-xia" data-btn="xia" title="下移"></div>
      </div>
    </div>
    <div class="btn btn-switch" title="云台控制器" @click="show=!show"></div>
  </div> 
</template>

<script>
import { Form } from 'element-ui'
export default {
  props: {
    accessToken: String,
    deviceSerial: String,
    channelNo: Number
  },
  data () {
    return {
      show: false
    }
  },
  mounted () {
    if (!this.accessToken || !this.deviceSerial || !this.channelNo) {
      this.$refs.control.remove()
    }
  },
  methods: {
    controlDown (e) {
      if (e.target.dataset.btn) {
        let direction
        switch (e.target.dataset.btn) {
          case 'yuanjiao':
            direction = 11
            break
          case 'jinjiao':
            direction = 10
            break
          case 'shang':
            direction = 0
            break
          case 'xia':
            direction = 1
            break
          case 'zuo':
            direction = 2
            break
          case 'you':
            direction = 3
            break
          case 'jia':
            direction = 8
            break
          case 'jian':
            direction = 9
            break
        }
        if (direction || direction === 0) {
          const form = new FormData()
          form.set('accessToken', this.accessToken)
          form.set('deviceSerial', this.deviceSerial)
          form.set('channelNo', this.channelNo)
          form.set('speed', 1)
          form.set('direction', direction)
          fetch('https://open.ys7.com/api/lapp/device/ptz/start', { method: 'post', body: form })
            .then((res) => {
              if (res.ok) {
                res.json()
              } else {
                this.$message.error(res.statusText)
              }
            })
            .catch((err) => {
              this.$message.error(err.message)
            })
            .then((res) => {
              if (res.code !== '200') {
                this.$message.error(res.msg)
              }
            })
        }
      }
    },
    controlUp (e) {
      if (e.target.dataset.btn) {
        const form = new FormData()
        form.set('accessToken', this.accessToken)
        form.set('deviceSerial', this.deviceSerial)
        form.set('channelNo', this.channelNo)
        fetch('https://open.ys7.com/api/lapp/device/ptz/stop', { method: 'post', body: form })
          .then((res) => {
            if (res.ok) {
              res.json()
            } else {
              this.$message.error(res.statusText)
            }
          })
          .catch((err) => {
            this.$message.error(err.message)
          })
          .then((res) => {
            if (res.code !== '200') {
              this.$message.error(res.msg)
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-control{
    position: absolute;
    bottom:-700px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: calc(150 / 1920 * 100vw);
    min-width: 150px;
    height: calc(150 / 1920 * 100vw);
    min-height: 150px;
    padding: 5px 10px;
    padding-right: 5px;
    border-top-left-radius: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 100;
    .btn{
      width:  30px;
      height: 30px;
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      &:active{
        transform: scale(0.8);
      }
    }
    .btn-yuanjiao{
      margin-right: 70px;
      background-image: url(../../assets/button/yuanjiao.png);
    }
    .btn-jinjiao{
      background-image: url(../../assets/button/jinjiao.png);
    }
    .btn-close{
      background-image: url(../../assets/button/guanbi.png);
    }
    .btn-shang{
      background-image: url(../../assets/button/shang.png);
    }
    .btn-zanting{
      background-image: url(../../assets/button/zanting.png);
    }
    .btn-jian{
      margin-right: 15px;
      background-image: url(../../assets/button/jianhao.png);
    }
    .btn-zuo{
      background-image: url(../../assets/button/zuo.png);
    }
    .btn-you{
      background-image: url(../../assets/button/you.png);
    }
    .btn-jia{
      background-image: url(../../assets/button/jiahao.png);
    }
    .btn-xia{
      background-image: url(../../assets/button/xia.png);
    }
    >div:nth-child(3){
      width: calc(100% - 30px - 30px);
      padding-right: 30px;
      display: flex;
      justify-content: space-between;
    }
    >div:nth-child(4){
      display: flex;
    }
    >div:nth-child(5){
      width: calc(100% - 30px);
      padding-right: 30px;
      display: flex;
      justify-content: space-between;
    }
  }
  .btn-switch{
    position: absolute;
    top: 12px;
    right: 24px;
    width: 18px;
    height: 25px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABzklEQVRIia2WwUqWQRSG32MaLSQEyRA0bKVQmLpoVdfgTqRVt9BO+K/BeygIwS7AhBZRLty1aCHuJBAC+3+R/BOVXx45OiOfX47fDPjCwPDNed93zuF8M6MIYBB4B3wBfgJfgRYwpASAGWAV2OcSe8B7YPIaA3gB/OJm/AFe1S2At8BpgnMEzMfAEeB3IjDiAHhaEX8J9Bo4RxeZAMsNgREfKwZrmZwP5qWR9CRV5wr+SXoo6V6YD2Rw2m5ARmDEaDDYzSX0SeoWGDzI3HlExw2+FRBKse4lei3peyYxdtJORuyxpNk+M9uQtHTHO3fxN2a2ffXFfwxgq6HtxoFHwFli/S+wAkxFXatbhx9qLHRLFSdmthliBiX119bPzOywrlcPkpntAJ3QYVX0Gsry32brO58GPgHdRPpelsdhpErUDRrTdfGFWw6uKibCaMKJa16kBTyT9EPS/YYSqLBNTyXNeZ1bmeKlcM2WZ9CWNJxJLsnA0ekvEFdIuwTDXqJ2gfheGLlGbTf4nBm8bmY9Hz7P5Kx5i06F6+02eAvPRpbPM9r68soMBD+HUiYutFjfmn/LuvQrhMnw5PCnh6MTDq7nqfx9LcR4rOP6s0XSOXEDGcoFuezHAAAAAElFTkSuQmCC);
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 100;
  }
</style>