import {
    get,
    post
  }
    from '../common/axiosHttp.js'

// 获取水位站基本信息
export const getWaterLevel = params => get('/waterLevel/getWaterLevel', params);

// 
export const getLevelReport = params => get('/waterLevel/getLevelReport', params);

