<template>
  <div class="Water">
    <!-- 上面 -->
    <div class="Water-top flex">
      <div class="Water-top-left Card-b-c">
        <Titles Titles="监控视频" />
        <div
          class="levelVideo flex flex-wrap"
          v-loading="loading"
          element-loading-text="数据量过大，加载中..."
          style="margin: 0; padding: 0; width: 100%; height: 100%;"
        >
          <div
            class="flex flex-a-c flex-j-c"
            style="width:100%"
            v-if="total === 0"
          >
            <img
              src="../../assets/images/index/video.png"
              style="width:calc(330/1920*100vw); height:calc(329/1080*100vh)"
            />
          </div>
          <div class="levelItem" v-else>
            <div class="btn-arrow">
              <i
                class="el-icon-arrow-left"
                :style="{
                  'pointer-events': page > 1 ? '' : 'none',
                  opacity: page > 1 ? '1' : '0'
                }"
                @click="nextpage(page - 1)"
              ></i>
              <i
                class="el-icon-arrow-right"
                :style="{
                  'pointer-events': page !== total ? '' : 'none',
                  opacity: page !== total ? '1' : '0'
                }"
                @click="nextpage(page + 1)"
              ></i>
            </div>
            <BtnVideo
              v-if="videoList.videoType === 0"
              :accessToken="videoList.accessToken"
              :channelNo="videoList.channelNo"
              :deviceSerial="videoList.videoModel"
            />
            <div id="videoItem" style="width: 100%; height: 100%;"></div>
          </div>
          <!-- <div
            class="levelItem"
            v-for="item in videoList"
            :key="item.id"
            v-else
          >
            <BtnVideo
              v-if="item.videoType === 0"
              :accessToken="item.accessToken"
              :channelNo="item.channelNo"
              :deviceSerial="item.videoModel"
            />
            <div
              :id="`videoItem-${item.id}`"
              style="width: 100%; height: 100%;"
            ></div>
          </div> -->
        </div>
        <!-- 分页区域 -->
        <!-- <el-pagination
          :hide-on-single-page="true"
          background
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @prev-click="nextpage"
          @next-click="nextpage"
          @current-change="nextpage"
        >
        </el-pagination> -->
      </div>
      <div class="Water-top-right">
        <div class="Water-card flex">
          <!-- 警戒水位 -->
          <div class="cardOne flex">
            <div class="Water-img flex flex-a-c">
              <img src="../../assets/images/WaterLevel/against.png" alt="" />
            </div>
            <div class="flex flex-a-c" style="margin-left:calc(20/1920*100vw)">
              <ul>
                <li class="flex flex-a-c">
                  <span
                    style="display:block;max-width: calc(42/1920*100vw);overflow: hidden;white-space: nowrap;"
                    :contenteditable="siteWaterVo.deviceStatus === 0"
                    @blur="getBlurLevel"
                    class="font-s-24 font-w-b edit-span"
                    >{{ Number(siteWaterVo.warningLevel) }}</span
                  >
                  <span class="font-s-18"> cm</span>
                </li>
                <li class="m-top">
                  <span
                    class="font-s-18 "
                    style="letter-spacing:calc(3/1920*100vw);"
                    >警戒水位</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- 当前水位 -->
          <div class="cardTwo flex">
            <div class="Water-img flex flex-a-c">
              <img src="../../assets/images/WaterLevel/present.png" alt="" />
            </div>
            <div class="flex flex-a-c" style="margin-left:calc(20/1920*100vw)">
              <ul>
                <li>
                  <span
                    class="font-s-24 font-w-b"
                    :style="{
                      color:
                        Number(siteWaterVo.warningLevel) <
                        Number(siteWaterVo.currentLevel)
                          ? '#F1415A'
                          : 'greenyellow'
                    }"
                    >{{ Number(siteWaterVo.currentLevel) }}</span
                  ><span class="font-s-18 "> cm</span>
                </li>
                <li class="m-top">
                  <span
                    class="font-s-18 "
                    style="letter-spacing:calc(3/1920*100vw)"
                    >当前水位</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- 警戒电量 -->
          <div class="cardThree flex " v-if="isMains == 0 ? true : false">
            <div class="Water-img flex flex-a-c">
              <img src="../../assets/images/WaterLevel/quantity.png" alt="" />
            </div>
            <div class="flex flex-a-c" style="margin-left:calc(20/1920*100vw)">
              <ul>
                <li class="flex flex-a-c">
                  <span
                    style="display:block;max-width: calc(42/1920*100vw);overflow: hidden;white-space: nowrap;"
                    :contenteditable="siteWaterVo.deviceStatus === 0"
                    @blur="getBluralertPower"
                    class="font-s-24 font-w-b edit-span dianliang"
                    >{{ Number(siteWaterVo.alertPower) }}</span
                  ><span class="font-s-18 "> %</span>
                </li>
                <li class="m-top">
                  <span
                    class="font-s-18 "
                    style="letter-spacing:calc(3/1920*100vw)"
                    >警戒电量</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="cardFour flex flex-a-c flex-j-c" v-else>
            <p class="font-s-18">暂无数据</p>
          </div>
        </div>
        <!-- 状态信息 -->
        <div class="Water-status Card-b-c">
          <Titles Titles="状态信息" />
          <ul class="flex flex-wrap ">
            <li>
              <span>设备状态：</span>
              <span
                :class="
                  siteWaterVo.deviceStatus != 0
                    ? 'red'
                    : siteWaterVo.waterLevelAlarmStatus != 0
                    ? 'red'
                    : siteWaterVo.electricityAlarmStatus != 0
                    ? 'red'
                    : 'green'
                "
              >
                {{
                  siteWaterVo.deviceStatus != 0
                    ? "异常"
                    : siteWaterVo.waterLevelAlarmStatus != 0
                    ? "异常"
                    : siteWaterVo.electricityAlarmStatus != 0
                    ? "异常"
                    : "正常"
                }}
              </span>
            </li>
            <li>
              <span>水位报警状态：</span
              ><span
                :class="
                  siteWaterVo.waterLevelAlarmStatus == 0 ? 'green' : 'red'
                "
                >{{
                  siteWaterVo.waterLevelAlarmStatus == 0 ? "正常" : "报警"
                }}</span
              >
            </li>
            <li>
              <span>在线状态：</span
              ><span
                :class="siteWaterVo.deviceStatus == 0 ? 'green' : 'gray'"
                >{{ siteWaterVo.deviceStatus == 0 ? "在线" : "离线" }}</span
              >
            </li>
            <li v-if="isMains == 0 ? true : false">
              <span>电量报警状态：</span
              ><span
                :class="
                  siteWaterVo.electricityAlarmStatus == 0 ? 'green' : 'red'
                "
                >{{
                  siteWaterVo.electricityAlarmStatus == 0 ? "正常" : "报警"
                }}</span
              >
            </li>
          </ul>
        </div>
        <!-- 太阳能信息 -->
        <div class="Water-solar Card-b-c" v-if="isMains == 0 ? true : false">
          <Titles Titles="太阳能信息" />
          <ul class="flex flex-wrap ">
            <li>
              <span>光伏电压：</span
              ><span>{{ Number(siteWaterVo.pvVoltage).toFixed(1) }}(V)</span>
            </li>
            <li>
              <span style="margin-left:calc(15/1920*100vw)">光伏电流：</span
              ><span>{{ Number(siteWaterVo.pvCurrent).toFixed(1) }}(A)</span>
            </li>
            <li>
              <span>电池温度：</span
              ><span
                >{{
                  Number(siteWaterVo.batteryTemperature).toFixed(1)
                }}(℃)</span
              >
            </li>
            <li>
              <span>电池电压：</span
              ><span
                >{{ Number(siteWaterVo.batteryVoltage).toFixed(1) }}(V)</span
              >
            </li>
            <li>
              <span style="margin-left:calc(15/1920*100vw)">日发电量：</span
              ><span
                >{{ Number(siteWaterVo.dailyOutput).toFixed(1) }}(KWH)</span
              >
            </li>
            <li>
              <span>负载电流：</span
              ><span>{{ Number(siteWaterVo.loadCurrent).toFixed(1) }}(A)</span>
            </li>
            <li>
              <span>剩余电量：</span
              ><span>{{ Number(siteWaterVo.soc).toFixed(1) }}(%)</span>
            </li>
            <li style="width:calc(300/1920*100vw)">
              <span style="margin-left:calc(18/1920*100vw)">更新时间：</span
              ><span>{{
                siteWaterVo.updateTime
                  ? new Date(siteWaterVo.updateTime)
                      .toLocaleString("chinese", {
                        hour12: false,
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit"
                      })
                      .replace(/\//g, "-") +
                    " " +
                    new Date(siteWaterVo.updateTime).toLocaleString("chinese", {
                      hour12: false,
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit"
                    })
                  : siteWaterVo.updateTime
              }}</span>
            </li>
          </ul>
        </div>
        <!-- 市电信息 -->
        <!-- 太阳能信息 -->
        <div class="Water-solar Card-b-c" v-else>
          <Titles Titles="市电信息" />
          <ul class="flex flex-wrap ">
            <li>
              <span>A相电流：</span
              ><span>{{ Number(siteWaterVo.aCurrent).toFixed(1) }}(A)</span>
            </li>
            <li>
              <span>B相电流：</span
              ><span>{{ Number(siteWaterVo.bCurrent).toFixed(1) }}(A)</span>
            </li>
            <li>
              <span>C相电流：</span
              ><span>{{ Number(siteWaterVo.cCurrent).toFixed(1) }}(A)</span>
            </li>
            <li>
              <span>D相电流：</span
              ><span>{{ Number(siteWaterVo.dCurrent).toFixed(1) }}(A)</span>
            </li>
            <li>
              <span>A相电压：</span
              ><span>{{ Number(siteWaterVo.aVoltage).toFixed(1) }}(V)</span>
            </li>
            <li>
              <span>B相电压：</span
              ><span>{{ Number(siteWaterVo.bVoltage).toFixed(1) }}(V)</span>
            </li>
            <li>
              <span>C相电压：</span
              ><span>{{ Number(siteWaterVo.cVoltage).toFixed(1) }}(V)</span>
            </li>
            <li style="width:calc(320/1920*100vw)">
              <span style="margin-left:calc(36/1920*100vw)">更新时间：</span
              ><span>{{
                siteWaterVo.updateTime
                  ? new Date(siteWaterVo.updateTime).toLocaleString("chinese", {
                      hour12: false,
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit"
                    })
                  : siteWaterVo.updateTime
              }}</span>
            </li>
          </ul>
        </div>
        <!-- 更新时间 -->
        <!-- <div class="Water-update Card-b-c">
          <div class="title flex">
            <i class="line"></i>
            <div class="title-text font-s-20 font-w-b">更新时间</div>
            <div class="Watertest">
              <span>{{
                siteWaterVo.updateTime
                  ? new Date(siteWaterVo.updateTime).toLocaleString("chinese", {
                      hour12: false,
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit"
                    })
                  : siteWaterVo.updateTime
              }}</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 下面 -->
    <div
      class="Water-bottom Card-b-c"
      v-loading="loading"
      element-loading-text="数据量过大，加载中..."
    >
      <!-- 监控视频 -->
      <div class="title flex flex-a-c">
        <i class="line"></i>
        <div class="title-text font-s-20 font-w-b">水位信息</div>
        <!-- 时间选择器 -->
        <div class="block">
          <el-date-picker
            :editable="false"
            v-model="time"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <!-- 日表报 -->
        <div class="tiemChart">
          <el-select v-model="chart" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-button class="search" type="primary" @click="searchList"
          >搜索</el-button
        >
      </div>
      <!-- 图表 -->
      <div class="WaterChart">
        <Water-chart :chartData="chartsData" :tooltip="tooltip" />
      </div>
    </div>
  </div>
</template>

<script>
import Titles from "../../components/Titles";
import WaterChart from "./WaterChart";
import { getSitesList } from "../../api/main";
import { getWaterLevel, getLevelReport } from "../../api/WaterLevel";
import { getNowDayFn } from "../../utils/methods";
import { updateSiteLevel, updateSitePower } from "../../api/StationManage";
import BtnVideo from "../../components/BtnVideo/index.vue";

export default {
  components: { Titles, WaterChart, BtnVideo },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      loading: false,
      siteId: "",
      tooltip: {},
      deviceAddress: "",
      siteWaterVo: {},
      isMains: "",
      page: 1,
      size: 1,
      pageSize: 0,
      total: 0,
      time: "",
      videoUrl: [], // 视频地址
      videoList: {}, // 视频列表
      chart: "0", //报表值
      time: "",
      optionsList: [], //折线图列表
      chartsData: {
        dataX: [],
        dataY: []
      },
      options: [
        {
          id: "0",
          label: "日报表"
        },
        {
          id: "1",
          label: "月报表"
        },
        {
          id: "2",
          label: "年报表"
        }
      ],
      siteList: {}
    };
  },
  computed: {},
  watch: {
    // 监听路由变化
    $route: "myroute",
    "$store.state.wsSiteData"(e) {
      if (e.id === Number(this.$route.params.siteId)) {
        this.siteWaterVo = { ...e };
        this.isMains = e.isMains;
      }
    }
  },
  methods: {
    myroute() {
      // 重新赋值
      this.siteId = this.$route.params.siteId;
      this.getName();
      this.getWaterList();
      this.getVideo();
      this.chart = "0";
      this.nowDate = getNowDayFn();
      this.time = this.nowDate;
    },
    // 获取站点名称
    getName() {
      // 获取列表接口
      getSitesList().then(res => {
        this.siteList = res.data;
        this.siteList.forEach(item => {
          if (item.id == this.siteId) {
            // 站点名称赋值
            this.deviceAddress = item.deviceAddress;
          }
        });
        this.getOptionsList();
      });
    },
    // 获取水位站基本信息
    getWaterList() {
      getWaterLevel({ id: this.siteId }).then(res => {
        // console.log("getWaterList", res);
        this.siteWaterVo = res.data.siteWaterVo;
        this.isMains = res.data.isMains;
      });
    },
    // 翻页
    nextpage(value) {
      // console.log(value);
      this.page = value;
      this.getVideo();
    },
    // 获取水位站视频
    getVideo() {
      this.loading = true;
      this.$get(`/video/overview/${this.page}/${this.size}`, {
        siteId: this.siteId
      }).then(res => {
        // this.videoList = {};
        this.videoList = res.data.list[0];
        this.total = res.data.total;
        this.pageSize = res.data.pageSize;
        setTimeout(() => {
          const video = document.querySelector("#videoItem");
          if (video) {
            video.innerHTML = "";
            0;
            const player = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "videoItem",
              accessToken: this.videoList.accessToken,
              url: this.videoList.videoAddress,
              template: "simple",
              audio: 0
            });
            console.log(player);
          }
        }, 500);
        this.loading = false;
      });
    },
    // 获取折线图数据
    getOptionsList() {
      this.loading = true;
      getLevelReport({
        reportType: this.chart,
        time: this.time,
        deviceAddress: this.deviceAddress
      }).then(res => {
        this.optionsList = [];
        this.chartsData.dataX = [
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7"
        ];
        this.chartsData.dataY = [];
        this.chartsData.dataZ = "单位/小时";
        this.tooltip = {
          trigger: "axis",
          formatter: "{b} 时：{c} cm"
        };
        console.log(this.chartsData);
        this.optionsList = res.data.reportLevel;
        this.optionsList.forEach(item => {
          // this.chartsData.dataX.push(item.levelHour);
          this.chartsData.dataY.push(item.level);
        });
        this.loading = false;
      });
    },
    // 点击搜索的时候
    searchList() {
      this.loading = true;
      getLevelReport({
        reportType: this.chart,
        time: this.time,
        deviceAddress: this.deviceAddress
      }).then(res => {
        this.optionsList = [];
        this.chartsData.dataX = [
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7"
        ];
        this.chartsData.dataY = [];
        this.optionsList = res.data.reportLevel;
        this.optionsList.forEach(item => {
          if (this.chart == "0") {
            this.chartsData.dataX = [
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7"
            ];
            this.chartsData.dataZ = "单位/小时";
            this.tooltip = {
              trigger: "axis",
              formatter: "{b} 小时：{c} cm"
            };
          }
          if (this.chart == "1") {
            this.chartsData.dataX = [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
              "30"
            ];
            this.chartsData.dataZ = "单位/日";
            this.tooltip = {
              trigger: "axis",
              formatter: "{b} 日：{c} cm"
            };
          }
          if (this.chart == "2") {
            this.chartsData.dataX = [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12"
            ];
            this.chartsData.dataZ = "单位/月";
            this.tooltip = {
              trigger: "axis",
              formatter: "{b} 月：{c} cm"
            };
          }
          this.chartsData.dataY.push(item.level);
        });
        this.loading = false;
      });
    },
    // 搜索
    searchCharts() {
      this.loading = true;
      getLevelReport({
        reportType: this.chart,
        time: this.time,
        deviceAddress: this.deviceAddress
      }).then(res => {
        this.searchList = res.data.reportLevel; // 折线图数据
        this.loading = false;
      });
    },
    // 修改警戒水位
    async getBlurLevel(e) {
      try {
        const num = Number(e.target.innerText);
        if (Object.is(num, NaN)) {
          throw new Error("警戒水位必须为正整数");
        }
        if (!/^[0-9]+$/.test(num)) {
          throw new Error("警戒水位必须为正整数");
        }
        if (num > 999) {
          throw new Error("警戒水位不能超过999");
        }
        if (num <= 0) {
          throw new Error("警戒水位不能小于1");
        }
        if (
          num.toFixed(0) === Number(this.siteWaterVo.warningLevel).toFixed(0)
        ) {
          return;
        }
        await updateSiteLevel({
          id: this.siteId,
          warningLevel: num,
          alertPower: Number(this.siteWaterVo.alertPower).toFixed(0)
        });
        this.$message.success("修改成功");
      } catch (err) {
        e.target.innerText = Number(this.siteWaterVo.warningLevel).toFixed(0);
        this.$message.error(err.message);
      } finally {
        this.getWaterList();
      }
    },
    // 修改警戒电量
    async getBluralertPower(e) {
      try {
        const num = Number(e.target.innerText);
        if (Object.is(num, NaN)) {
          throw new Error("警戒电量必须为正数整");
        }
        if (!/^[0-9]+$/.test(num)) {
          throw new Error("警戒电量必须为正整数");
        }
        if (num > 100) {
          throw new Error("警戒电量不能超过100");
        }
        if (num <= 0) {
          throw new Error("警戒电量不能小于1");
        }
        if (num.toFixed(0) === Number(this.siteWaterVo.alertPower).toFixed(0)) {
          return;
        }
        await updateSitePower({
          id: this.siteId,
          warningLevel: Number(this.siteWaterVo.warningLevel).toFixed(0),
          alertPower: num
        });
        this.$message.success("修改成功");
      } catch (err) {
        e.target.innerText = Number(this.siteWaterVo.alertPower).toFixed(0);
        this.$message.error(err.message);
      } finally {
        this.getWaterList();
      }
    }
  },
  created() {
    this.myroute();
    window.onload = function() {
      document.body.onkeydown = function(event) {
        if (event.keyCode == 13) {
          event.keyCod = 0;
          return false;
        }
      };
    };
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.edit-span {
  border: 3px #ffe8e8 solid;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
}
.m-top {
  margin-top: 5px;
}
.dianliang {
  border: 3px #e8fffe solid;
}
.Water {
  width: calc(1575 / 1920 * 100vw);
  height: calc(960 / 1080 * 100vh);
  .Water-top {
    .Water-top-left {
      width: calc(895 / 1920 * 100vw);
      height: calc(645 / 1080 * 100vh);
      margin-right: calc(20 / 1920 * 100vw);
      box-sizing: border-box;

      .levelVideo {
        margin: calc(17 / 1080 * 100vh) calc(10 / 1920 * 100vw);
        height: calc(495 / 1080 * 100vh);

        .levelItem {
          position: relative;
          width: calc(100% - calc(20 / 1920 * 100vw));
          height: calc(100% - calc(68 / 1080 * 100vh));
          margin: 0 calc(10 / 1920 * 100vw);
          margin-top: calc(8 / 1080 * 100vh);
          // margin-top: calc(14 / 1080 * 100vh);
          // margin-left: calc(16 / 1920 * 100vw);
          background-color: #000;
          .btn-arrow {
            position: absolute;
            top: 50%;
            display: flex;
            justify-content: space-between;
            width: calc(100% - calc(40 / 1920 * 100vw));
            padding: 0 calc(20 / 1920 * 100vw);
            z-index: 99;
            > i {
              background: #fff;
              color: #000;
              padding: 10px;
              border-radius: 50%;
              cursor: pointer;
              transform: translateY(-50%);
              &:active {
                transform: translateY(-37.5%);
              }
            }
          }
        }
      }
    }
  }
  .Water-top-right {
    width: calc(660 / 1920 * 100vw);
    height: calc(645 / 1080 * 100vh);
    .Water-card {
      height: calc(134 / 1080 * 100vh);
      margin-bottom: calc(15 / 1080 * 100vh);
      .cardOne {
        width: calc(212 / 1920 * 100vw);
        box-shadow: 0px 0px 7px 0px rgba(44, 42, 55, 0.4);
        border-radius: 8px;
        height: 100%;
        margin-right: calc(10 / 1920 * 100vw);
        background: url("../../assets/images/WaterLevel/againstB.png");
        background-size: 100% 100%;
        ul {
          color: #f9f8fd;
          margin-left: calc(10 / 1920 * 100vw);
        }
        .Water-img {
          padding-left: calc(24 / 1920 * 100vw);
          height: 100%;
          width: calc(52 / 1920 * 100vw);
          img {
            width: 100%;
            height: calc(52 / 1080 * 100vh);
          }
        }
      }
      .cardTwo {
        width: calc(212 / 1920 * 100vw);
        background: url("../../assets/images/WaterLevel/presentB.png");
        background-size: 100% 100%;
        box-shadow: 0px 0px 7px 0px rgba(44, 42, 55, 0.4);
        border-radius: 8px;
        height: 100%;
        margin-right: calc(10 / 1920 * 100vw);
        ul {
          color: #f9f8fd;
          margin-left: calc(10 / 1920 * 100vw);
        }
        .Water-img {
          padding-left: calc(24 / 1920 * 100vw);
          height: 100%;
          width: calc(48 / 1920 * 100vw);
          img {
            height: calc(52 / 1080 * 100vh);
          }
        }
      }
      .cardThree {
        width: calc(212 / 1920 * 100vw);
        background: url("../../assets/images/WaterLevel/quantityB.png");
        background-size: 100% 100%;
        box-shadow: 0px 0px 7px 0px rgba(44, 42, 55, 0.4);
        border-radius: 8px;
        height: 100%;
        ul {
          color: #f9f8fd;
          margin-left: calc(10 / 1920 * 100vw);
        }
        .Water-img {
          padding-left: calc(24 / 1920 * 100vw);
          height: 100%;
          width: calc(32 / 1920 * 100vw);
          img {
            height: calc(52 / 1080 * 100vh);
          }
        }
      }
      .cardFour {
        width: calc(212 / 1920 * 100vw);
        background: url("../../assets/images/WaterLevel/quantityB.png");
        background-size: 100% 100%;
        box-shadow: 0px 0px 7px 0px rgba(44, 42, 55, 0.4);
        border-radius: 8px;
        height: 100%;
        font-weight: bold;
        color: #f9f8fd;
      }
    }
    // 状态信息
    .Water-status {
      height: calc(177 / 1080 * 100vh);
      margin-bottom: calc(15 / 1080 * 100vh);
      ul {
        width: 100%;
        color: #666666;
        li {
          font-weight: bold;
          width: 50%;
          height: calc(65 / 1080 * 100vh);
          display: flex;
          align-items: center;
          span {
            font-size: calc(18 / 1920 * 100vw);
          }
          span:nth-child(1) {
            margin-left: calc(35 / 1920 * 100vw);
          }
        }
      }
    }
    // 太阳能信息
    .Water-solar {
      height: calc(304 / 1080 * 100vh);
      margin-bottom: calc(15 / 1080 * 100vh);
      ul {
        width: 100%;
        li {
          font-weight: bold;
          width: 33%;
          height: calc(80 / 1080 * 100vh);
          display: flex;
          align-items: center;
          span {
            font-size: calc(18 / 1920 * 100vw);
          }
          span:nth-child(1) {
            color: #666666;
            margin-left: calc(35 / 1920 * 100vw);
          }
          span:nth-child(2) {
            color: #333;
          }
        }
      }
    }
    // 更新时间
    .Water-update {
      height: calc(68 / 1080 * 100vh);
      .title {
        position: relative;
        height: calc(68 / 1080 * 100vh);
        .line::after {
          content: "";
          width: calc(3 / 1920 * 100vw);
          height: calc(17 / 1080 * 100vh);
          background-color: #3669ab;
          position: absolute;
          left: calc(17 / 1920 * 100vw);
          top: calc(28 / 1080 * 100vh);
        }
        .Watertest {
          color: #4073b6;
          display: flex;
          align-items: center;
          span {
            margin-left: calc(20 / 1920 * 100vw);
            font-size: calc(30 / 1920 * 100vw);
            font-weight: bold;
          }
        }
        .title-text {
          width: 25%;
          height: 100%;
          text-indent: calc(34 / 1920 * 100vw);
          line-height: calc(68 / 1080 * 100vh);
          color: #000000;
        }
        .text {
          margin-top: calc(14 / 1080 * 100vh);
          width: calc(49 / 1920 * 100vw);
          height: calc(24 / 1080 * 100vh);
          text-align: center;
          background: #e3f2ff;
          border-radius: 12px;
          line-height: calc(24 / 1080 * 100vh);
          p {
            color: #2c599b;
            font-size: calc(20 / 1920 * 100vw);
            font-weight: bold;
          }
        }
        .message {
          margin-top: calc(12 / 1080 * 100vh);
          color: red;
          .big {
            font-size: calc(20 / 1920 * 100vw);
          }
          .small {
            font-size: calc(16 / 1920 * 100vw);
            color: #7d7d7d;
          }
        }
      }
    }
  }
  .Water-bottom {
    margin-top: calc(18 / 1080 * 100vh);
    width: calc(1575 / 1920 * 100vw);
    height: calc(297 / 1080 * 100vh);
    .WaterChart {
      width: 100%;
      height: calc(249 / 1080 * 100vh);
    }
    .title {
      position: relative;
      height: calc(48 / 1080 * 100vh);
      .line::after {
        content: "";
        width: calc(3 / 1920 * 100vw);
        height: calc(17 / 1080 * 100vh);
        background-color: #3669ab;
        position: absolute;
        left: calc(17 / 1920 * 100vw);
        top: calc(17 / 1080 * 100vh);
      }
      .title-text {
        width: 70.5%;
        height: 100%;
        text-indent: calc(34 / 1920 * 100vw);
        line-height: calc(48 / 1080 * 100vh);
        color: #000000;
      }
      border-bottom: calc(2 / 1080 * 100vh) #2e5fa0 solid;
      .text {
        margin-top: calc(14 / 1080 * 100vh);
        width: calc(49 / 1920 * 100vw);
        height: calc(24 / 1080 * 100vh);
        text-align: center;
        background: #e3f2ff;
        border-radius: 12px;
        line-height: calc(24 / 1080 * 100vh);
        p {
          color: #2c599b;
          font-size: calc(20 / 1920 * 100vw);
          font-weight: bold;
        }
      }
      .message {
        margin-top: calc(12 / 1080 * 100vh);
        color: red;
        .big {
          font-size: calc(20 / 1920 * 100vw);
        }
        .small {
          font-size: calc(16 / 1920 * 100vw);
          color: #7d7d7d;
        }
      }
    }
  }
}

.block {
  .el-date-editor {
    margin-right: calc(10 / 1920 * 100vw);
    width: calc(178 / 1920 * 100vw);
    height: calc(38 / 1080 * 100vh) !important;
    background: #ffffff !important;
    border-radius: 8px;
  }
  ::v-deep .el-date-editor .el-range-input {
    height: calc(38 / 1080 * 100vh);
    background: #ffffff !important;
  }
  ::v-deep .el-input__inner {
    height: calc(38 / 1080 * 100vh) !important;
    border: 1px solid #c0c4cc !important;
  }
  ::v-deep .el-range-separator {
    background: #ffffff !important;
  }
  ::v-deep.el-input__icon {
    display: none !important;
  }
}

.tiemChart {
  margin-right: calc(10 / 1920 * 100vw);
  .el-select {
    width: calc(178 / 1920 * 100vw);
    height: calc(38 / 1080 * 100vh) !important;
  }
  ::v-deep .el-select .el-input__inner {
    height: calc(38 / 1080 * 100vh) !important;
  }
  ::v-deep .el-input__icon {
    line-height: calc(38 / 1080 * 100vh);
  }
}
.el-pagination {
  margin-top: calc(25 / 1080 * 100vh);
}
::v-deep .el-input__icon {
  font-size: calc(10 / 1920 * 100vw) !important;
}
</style>
